export default [
 
  {
    path: '/gwql',
    name: 'gwql',
    component: () => import(/* webpackChunkName: "gwql" */ '../views/index/gwql.vue')
  },
  {
    path: '/news',
    name: 'news',
    component: () => import(/* webpackChunkName: "news" */ '../views/index/news.vue')
  },
  {
    path: '/shareDetail',
    name: 'shareDetail',
    component: () => import(/* webpackChunkName: "shareDetail" */ '../views/share/shareDetail.vue')
  },
  {
    path: '/cash',
    name: 'cash',
    component: () => import(/* webpackChunkName: "cash" */ '../views/mine/cash.vue')
  },
  {
    path: '/bindBank',
    name: 'bindBank',
    component: () => import(/* webpackChunkName: "bindBank" */ '../views/mine/bindBank.vue')
  },
  {
    path: '/real',
    name: 'real',
    component: () => import(/* webpackChunkName: "real" */ '../views/mine/real.vue')
  },
  {
    path: '/cashDetail',
    name: 'cashDetail',
    component: () => import(/* webpackChunkName: "cashDetail" */ '../views/mine/cashDetail.vue')
  },
  {
    path: '/moneyDetail',
    name: 'moneyDetail',
    component: () => import(/* webpackChunkName: "moneyDetail" */ '../views/mine/moneyDetail.vue')
  },
  {
    path: '/appxz',
    name: 'appxz',
    component: () => import(/* webpackChunkName: "appxz" */ '../views/mine/appxz.vue')
  },
  
  {
    path: '/zcwj',
    name: 'zcwj',
    component: () => import(/* webpackChunkName: "zcwj" */ '../views/index/zcwj.vue')
  },
  {
    path: '/iframe',
    name: 'iframe',
    component: () => import('../views/index/iframe.vue')
  },
  {
    path: '/yq',
    name: 'yq',
    component: () => import('../views/index/yq.vue')
  },
  {
    path: '/buyDetail',
    name: 'buyDetail',
    component: () => import('../views/mine/buyDetail.vue')
  },
  {
    path: '/xjhb',
    name: 'xjhb',
    component: () => import('../views/index/xjhb.vue')
  },
  {
    path: '/gf',
    name: 'gf',
    component: () => import('../views/gf/gf.vue')
  },
  
  // {
  //   path: '/hhrqy',
  //   name: 'hhrqy',
  //   component: () => import('../views/index/hhrqy.vue')
  // },
]